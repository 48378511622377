import axios from "axios";
import config from "./config";

const axiosLog = axios.create({
  baseURL: config.baseURL,
  // withCredentials: true,

  // other configurations
});

export default axiosLog;
