import { useState } from "react";
import { Modal, ModalHeader } from "react-bootstrap";

const parameter = { id: "", val: "", edit: "" };

function ModalEditFile({ show, onHide, submitClick, data = {} }) {
  const attr = {
    title: data.hasOwnProperty("title") ? data.title : "Edit Value",
    text: data.hasOwnProperty("text") ? data.text : "Please edit as you wish :)",
    data: data.hasOwnProperty("data") ? data.data : { id: "", val: "", edit: "" },
  };
  const [val, setVal] = useState(attr.data);
  const [val2, setVal2] = useState(false);
  const reducer = require("image-blob-reduce")();

  const handleChange = (e) => {
    if (e.target.value) {
      reducer.toBlob(e.target.files[0], { max: 500 }).then((blob) => {
        let formData = new FormData();
        formData.append("file", blob);
        formData.append("id", attr.data.id);
        formData.append("edit", attr.data.edit);
        setVal(formData);
        setVal2(true);
      });
    } else {
      setVal2(false);
    }
  };

  function onShow() {
    setVal2(false);
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setVal(parameter);
          onHide();
        }}
        onShow={onShow}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalHeader closeButton>
              <h5 className="modal-title">{attr.title}</h5>
            </ModalHeader>
            <div className="modal-body">
              <div className="mb-3">
                <input type="file" onChange={(e) => handleChange(e)} accept="image/jpg,image/png,image/jpeg" className="form-control" />
              </div>
              <div className="alert bg-inverse bg-opacity-15">{attr.text}</div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={() => submitClick(val)} className={val2 ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalEditFile;
