import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function ModalEditProfilePhoneNumber({ show, onHide, submit, data = { id: null, val: null } }) {
  const [disable, setDisable] = useState(false);
  const [phone, setPhone] = useState();
  function handleOnHide() {
    onHide();
  }
  function handleClickSubmit(val) {
    setPhone();
    submit({ id: data.id, val: val });
  }
  function onShow() {}
  useEffect(() => {
    setDisable(phone ? false : true);
  }, [phone]);
  return (
    <>
      <Modal show={show} onShow={onShow} onHide={() => handleOnHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit phone number</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label className="form-label">Phone</label>
            <input onChange={(e) => setPhone(e.target.value)} type="text" defaultValue={data.val} className="form-control" />
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => handleClickSubmit(phone)} disabled={disable} type="button" className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default ModalEditProfilePhoneNumber;
