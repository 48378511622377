import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function ModalEditProfileImage({ show, onHide, submit, data }) {
  const [file, setFile] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [img, setImg] = useState();
  const reducer = require("image-blob-reduce")();

  function handleOnHide() {
    onHide();
  }
  function onShow() {
    setImg("");
  }

  function handleChangeFile(val) {
    reducer.toBlob(val, { max: 200 }).then((blob) => {
      setImg(URL.createObjectURL(blob));
      setFile(blob);
    });
  }
  function handleClick(val) {
    setFile();
    submit(val);
  }

  useEffect(() => {
    setSubmitDisabled(file ? false : true);
  }, [file]);
  return (
    <>
      <Modal show={show} onShow={onShow} onHide={() => handleOnHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit profile image</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <input onChange={(e) => handleChangeFile(e.target.files[0])} type="file" className="form-control" accept="image/*" />
          </div>
          {img ? <img src={img} className="img-thumbnail" alt="image" /> : ""}
        </ModalBody>
        <ModalFooter>
          <button onClick={() => handleClick(file)} disabled={submitDisabled} type="button" className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default ModalEditProfileImage;
