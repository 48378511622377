import { useEffect, useState } from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../config/axios.js";

const parameter = { id: "", val: "", edit: "" };

function ModalEditStatus({ show, onHide, submitClick, data = {}, headerFetch }) {
  const attr = {
    title: data.hasOwnProperty("title") ? data.title : "Edit Value",
    text: data.hasOwnProperty("text") ? data.text : "Please edit as you wish :)",
    data: data.hasOwnProperty("data") ? data.data : { id: "", val: "", edit: "" },
  };
  const [option, setOption] = useState([]);
  const [statusSellected, setStatusSelected] = useState("");
  const [date, setDate] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [val, setVal] = useState(attr.data);
  const getOption = () => {
    axiosInstance.get("/optionselector/" + attr.data.edit, headerFetch).then((res) => setOption(res.data));
  };

  function handleOnHide() {
    setStatusSelected("");
    setSubmitEnabled(false);
    setVal(parameter);
    onHide();
  }

  function handleChangeStatus(e) {
    setStatusSelected(e.value);
  }

  function handleChangeDate(e) {
    setDate(e.target.value);
  }

  useEffect(() => {
    if (statusSellected) {
      if (statusSellected == "AKTIF") {
        setSubmitEnabled(true);
        setVal({ id: attr.data.id, val: { status: statusSellected, notActiveDate: null }, edit: attr.data.edit });
      } else {
        if (statusSellected != "AKTIF" && date) {
          setSubmitEnabled(true);
          setVal({ id: attr.data.id, val: { status: statusSellected, notActiveDate: date }, edit: attr.data.edit });
        } else {
          setSubmitEnabled(false);
        }
      }
    } else {
      setSubmitEnabled(false);
    }
  }, [statusSellected, date]);

  return (
    <>
      <Modal show={show} onShow={() => getOption()} onHide={() => handleOnHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">{attr.title}</h5>
        </ModalHeader>
        <div className="modal-body">
          <div className="mb-3">
            <label className="form-label">Status</label>
            <Select onChange={(e) => handleChangeStatus(e)} options={option} isSearchable classNamePrefix="react-select" />
          </div>
          {statusSellected && statusSellected != "AKTIF" ? (
            <div className="mb-3">
              <label className="form-label">Not Active Date</label>
              <input type="date" onChange={(e) => handleChangeDate(e)} className="form-control" />
            </div>
          ) : null}
          <div className="alert bg-inverse bg-opacity-15">{attr.text}</div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={() => submitClick(val)} className={submitEnabled ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ModalEditStatus;
