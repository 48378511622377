import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import axiosLog from "../../config/axiosLog.js";
import config from "../../config/config.js";

function PagesLogin() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [psw, setPsw] = useState();
  const [enable, setEnable] = useState(false);
  const [failLog, setFailLog] = useState(false);

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axiosLog
      .post("/loginV2", { name: event.target[0].value, password: event.target[1].value })
      .then((res) => {
        sessionStorage.setItem("nuzulul", res.data.access_token);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setFailLog(true);
      });
  }

  function handleChangeUser(val) {
    setUser(val);
  }
  function handleChangePsw(val) {
    setPsw(val);
  }

  useEffect(() => {
    if (user && psw) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [user, psw]);

  if (redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login">
      <div className="login-content">
        <form onSubmit={handleSubmit}>
          <div className="text-center">
            <img src="assets/img/logo/ppd.png" alt="ppd logo" height="40" />
          </div>
          <h1 className="text-center">Sign In</h1>
          <div className="text-inverse text-opacity-50 text-center mb-4">For your protection, please verify your identity.</div>
          {failLog ? <div className="text-danger text-center">Sorry... invalid user name or password.</div> : ""}
          <div className="mb-3">
            <label className="form-label">
              User Name <span className="text-danger">*</span>
            </label>
            <input onChange={(e) => handleChangeUser(e.target.value)} type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
          </div>
          <div className="mb-3">
            <div className="d-flex">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              {/* <a href="#/" className="ms-auto text-inverse text-decoration-none text-opacity-50">Forgot password?</a> */}
            </div>
            <input onChange={(e) => handleChangePsw(e.target.value)} type="password" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
          </div>
          {/* <div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">Remember me</label>
						</div>
					</div> */}

          {loading ? (
            <div className="text-center">
              <div className="spinner-border"></div>
            </div>
          ) : (
            <button type="submit" disabled={enable ? false : true} className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">
              Sign In
            </button>
          )}
          {/* <div className="text-center text-inverse text-opacity-50">
						Don't have an account yet? <Link to="/pages/register">Sign up</Link>.
					</div> */}
        </form>
      </div>
    </div>
  );
}

export default PagesLogin;
