function getHeader() {
  const headers = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
    },
  };
  return headers;
}

export default getHeader;
