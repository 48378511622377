import React from "react";
import { jsPDF } from "jspdf";
import config from "../../config/config";

function GenerateTaf(data) {
  generateTaf(data.values, data.peserta, 1);
}
export default GenerateTaf;

async function generateTaf(main, peserta, hal) {
  const doc = new jsPDF();
  let pdf;
  const maxPage = Math.ceil(peserta.length / 12);
  let currentPage = 0;
  for (let index = 0; index < peserta.length; index += 12) {
    const pesertaPrint = peserta.slice(index, index + 12);
    currentPage++;
    pdf = await generatePdf(doc, main, pesertaPrint, currentPage, maxPage);
    if (pesertaPrint.length < 12) {
      pdf.doc.save(pdf.fileName);
    } else {
      pdf.doc.addPage(pdf.fileName);
    }
  }
}

async function generatePdf(doc, main, peserta, currentPage, maxPage) {
  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agu", "Sep", "Oct", "Nov", "Dec"];
  var img = new Image();
  var tgl;
  var namaTaf;

  function formatDate(date) {
    const t = new Date(date);
    const day = t.getDate() < 10 ? "0" + t.getDate() : t.getDate();

    return `${day}-${month[t.getMonth()]}-${t.getFullYear()}`;
  }

  if (main.start == main.end) {
    tgl = formatDate(main.start);
  } else {
    tgl = formatDate(main.start) + " - " + formatDate(main.end);
  }

  namaTaf = "TAF TRAINING " + main.trainingTitle + " TANGGAL " + tgl + ".pdf";

  doc.setFontSize(7);
  doc.text(175, 9, "Halaman : " + currentPage + "/" + maxPage);
  doc.text(175, 15, "Form No. SFC/09/F-004");

  doc.setFontSize(7);
  doc.text(15, 50, "No.");
  doc.text(30, 50, ": " + main.tafCode);
  doc.text(80, 50, "Date : " + formatDate(main.createDate));
  doc.text(115, 50, "Application Reference : ATC/NON ATC *)  ATP/NON ATP *)");

  doc.text(15, 58, "To");
  doc.text(30, 58, ": " + main.to);

  doc.text(15, 66, "From");
  doc.text(30, 66, ": " + main.from);

  doc.text(15, 74, "Subject");
  doc.text(30, 74, ": " + main.subject);

  doc.text(15, 82, "Dengan ini, kami mohon persetujuan dan pendaftaran untuk mengikuti Pelatihan / Kursus / Seminar / Lokakarya atas nama Pekerja di bawah ini :");

  doc.text(15, 172, "Adapun Pelatihan / Kursus / Seminar / Lokakarya *) yang akan diikuti karyawan tersebut, adalah :");
  doc.text(15, 180, "Nama Pelatihan / Kursus / Seminar / Lokakarya");
  doc.text(90, 180, ": " + main.trainingTitle);
  doc.text(15, 186, "Lokasi/Tanggal Pelaksanaan");
  doc.text(90, 186, ": " + main.lokasi + ", " + tgl);
  doc.text(15, 192, "Penyelenggara");
  doc.text(90, 192, ": " + main.penyelenggara);
  doc.text(15, 198, "Biaya");
  doc.text(90, 198, ": " + main.biaya);
  doc.text(15, 208, "Terlampir bersama ini, brosur/informasi mengenai pelatihan / kursus / seminar / lokakarya* tersebut.");
  doc.text(15, 216, "MACO   , " + formatDate(main.createDate));
  doc.text(15, 224, "Diajukan Oleh,");
  doc.text(15, 228, "PM/ Dept. Head");
  doc.text(15, 244, main.diajukanOleh);
  doc.text(15, 248, "Tanggal :");

  doc.text(60, 224, "Disetujui Oleh,");
  doc.text(60, 228, "Related Div. Head/ Director");
  doc.text(60, 244, main.disetujuiOleh);
  doc.text(60, 248, "Tanggal :");

  doc.text(100, 224, "Diperiksa Oleh,");
  doc.text(100, 228, "Dept. Head");
  doc.text(100, 244, main.diperiksaOleh);
  doc.text(100, 248, "Tanggal :");

  doc.text(140, 224, "Setuju / Tidak Setuju *),");
  doc.text(140, 228, "Div. Head PEDD/ Director HR");
  doc.text(140, 244, main.divHead);
  doc.text(140, 248, "Tanggal :");

  doc.text(15, 263, "Catatan            :");
  doc.text(50, 263, ".............................................................................................................................................................................................................");
  doc.text(50, 271, ".............................................................................................................................................................................................................");
  doc.text(15, 276, "*) Coret yang tidak perlu.");
  doc.text(15, 281, "**) Khusus u/ Pengajuan dari Jobsite harus melalui persetujuan Fungsional Dept Head/Direktur di Head Office");

  doc.text(180, 285, "Revisi:1");

  doc.setLineWidth(0.3);
  doc.line(14, 245, 45, 245);
  doc.line(59, 245, 89, 245);
  doc.line(97, 245, 127, 245);
  doc.line(139, 245, 191, 245);
  doc.line(14, 255, 191, 255);

  doc.text("No.", 20, 98, "center");
  doc.text("NAMA", 45, 98, "center");
  doc.text("NRP", 78, 98, "center");
  doc.text("DEPARTMENT", 95, 98, "center");
  doc.text("JABTAN", 118, 98, "center");
  doc.text("  MULAI\rBEKERJA", 140, 98, "center");

  doc.text("    PARAF PERSETUJUAN\rATASAN FUNGSIONAL HO (DIV\r   HEAD / DEPT HEAD / SECT \r         HEAD)**)", 169, 95, "center");

  doc.setLineWidth(0.5);
  doc.line(15, 90, 190, 90);

  doc.line(15, 105, 190, 105);
  doc.line(15, 90, 15, 165);
  doc.line(25, 90, 25, 165);
  doc.line(190, 90, 190, 165);
  doc.line(150, 90, 150, 165);
  doc.line(130, 90, 130, 165);
  doc.line(105, 90, 105, 165);
  doc.line(85, 90, 85, 165);
  doc.line(70, 90, 70, 165);

  doc.setLineWidth(0.3);
  doc.line(15, 110, 190, 110);
  doc.line(15, 115, 190, 115);
  doc.line(15, 120, 190, 120);
  doc.line(15, 125, 190, 125);
  doc.line(15, 130, 190, 130);
  doc.line(15, 135, 190, 135);
  doc.line(15, 140, 190, 140);
  doc.line(15, 145, 190, 145);
  doc.line(15, 150, 190, 150);
  doc.line(15, 155, 190, 155);
  doc.line(15, 160, 190, 160);

  doc.setLineWidth(0.5);
  doc.line(15, 165, 190, 165);

  //------------Table content--------add line spacing 5//

  var init = 108;
  peserta.forEach((pes, index) => {
    doc.text(19, init, (index + 1).toString());
    doc.text(26, init, pes.peserta.nama);
    doc.text(72, init, pes.peserta.perusahaan != 1 ? "-" : pes.peserta.nrp);
    doc.text(95, init, pes.peserta.section, "center");
    doc.text(118, init, pes.peserta.jabatanStr, "center");
    doc.text(140, init, formatDate(pes.peserta.jointDate), "center");
    init += 5;
  });

  doc.setFontSize(11);
  doc.setFont("Helvetica");
  doc.text(100, 30, "TRAINING APPLICATION FORM (TAF)", "center");
  doc.setLineWidth(0.5);
  doc.line(65, 31, 135, 31);

  doc.setFontSize(6);
  doc.setFont("times", "italic");
  doc.text(15, 290, "This document was generated from the ppdmaco.com at:" + new Date());
  let image = new Promise((resolve, reject) => {
    img.src = config.baseURL + "/generalImage/sisLogo";
    img.onload = function () {
      doc.addImage(img, "jpg", 15, 20, 35, 12);
      resolve(true);
    };
  });
  let ii = await image;
  if (ii) return { doc: doc, fileName: namaTaf };
  // doc.save(namaTaf);
}
