function DateTimeFormat(date) {
  const d = new Date(date);
  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agu", "Sep", "Oct", "Nov", "Dec"];
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const second = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

  return `${day}-${month[d.getMonth()]}-${d.getFullYear()}  ${hour}:${minute}:${second}`;
}

export default DateTimeFormat;
