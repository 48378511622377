import React, { useEffect, useState } from "react";
import { Card } from "./../../components/card/card.jsx";
import ModalEditProfileName from "../../components/modal/modalEditProfileName.jsx";
import ModalEditProfilePassword from "../../components/modal/modalEditProfilePassword.jsx";
import ModalEditProfilePhoneNumber from "../../components/modal/modalEditProfilePhone.jsx";
import ModalEditProfileImage from "../../components/modal/modalEditProfileImage.jsx";
import axiosInstance from "../../config/axios.js";
import getHeader from "../../config/Headers.js";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import ModalLoading from "../../components/modal/modalLoading.jsx";
import { base64ToBlob } from "file64";

function Settings() {
  const [modalPhone, setModalPhone] = useState({ show: false, data: { id: null, val: null } });
  const [modalName, setModalName] = useState({ show: false, data: { id: null, val: null } });
  const [modalPass, setModalPass] = useState({ show: false, error: { old: [], new1: [], new2: [] }, data: { id: null, val: null } });
  const [modalImage, setModalImage] = useState({ show: false, data: { id: null, val: null } });
  const [userData, setUserData] = useState();
  const [progressValue, setProggressValue] = useState(0);
  const [modalLoading, setModalLoading] = useState(false);
  const [imageVisible, setImageVisible] = useState(true);
  function handelSubmitPhone(val) {
    setModalPhone({ ...modalPhone, show: false });
    axiosInstance
      .put("user-edit/phone", { phone: val.val }, getHeader())
      .then((res) => setUserData(res.data))
      .catch((e) => {});
  }
  function handelSubmitName(val) {
    setModalName({ ...modalName, show: false });
    axiosInstance
      .put("user-edit/name", { name: val.val }, getHeader())
      .then((res) => setUserData(res.data))
      .catch((e) => {});
  }
  function handelSubmitPass(val) {
    axiosInstance
      .put("user-edit/pass", val.val, getHeader())
      .then((res) => {
        if (res.data.status == "notValid") {
          setModalPass({ ...modalPass, error: res.data.message });
        } else {
          setModalPass({ ...modalPass, show: false, error: { old: [], new1: [], new2: [] } });
          setUserData(res.data);
        }
      })
      .catch((e) => {});
  }
  function handelSubmitImage(val) {
    setImageVisible(false);
    let formData = new FormData();
    formData.append("file", val);
    setModalImage({ ...modalImage, show: false });
    setModalLoading(true);
    axiosInstance
      .post("/userImage-set", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setProggressValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        setModalLoading(false);
        setImageVisible(true);
        setProggressValue(0);
        setUserData(res.data);
      })
      .catch((e) => {});
  }

  useEffect(() => {
    axiosInstance
      .get("/user", getHeader())
      .then((res) => setUserData(res.data))
      .catch((e) => {});
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <div id="general" className="mb-5">
                  <h4>
                    <i className="far fa-user fa-fw text-theme"></i> Profile Edit
                  </h4>
                  <p>View and update your general account information.</p>
                  {userData ? (
                    <Card>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex align-items-center">
                          <div className="flex-1 text-break">
                            <div>Name</div>
                            <div className="text-inverse text-opacity-50">{userData.name}</div>
                          </div>
                          <div className="w-100px">
                            <a href="#/" onClick={() => setModalName({ show: true, data: { id: 1, val: userData.name } })} className="btn btn-outline-default w-100px">
                              Edit
                            </a>
                          </div>
                        </div>
                        <div className="list-group-item d-flex align-items-center">
                          <div className="flex-1 text-break">
                            <div>Phone</div>
                            <div className="text-inverse text-opacity-50">{userData.phone}</div>
                          </div>
                          <div>
                            <a href="#/" onClick={() => setModalPhone({ show: true, data: { id: 1, val: userData.phone } })} className="btn btn-outline-default w-100px">
                              Edit
                            </a>
                          </div>
                        </div>

                        <div className="list-group-item d-flex align-items-center">
                          <div className="flex-1 text-break">
                            <div>Password</div>
                            <div className="text-inverse text-opacity-50">*********</div>
                          </div>
                          <div>
                            <a href="#/" onClick={() => setModalPass({ ...modalPass, show: true })} className="btn btn-outline-default w-100px">
                              Edit
                            </a>
                          </div>
                        </div>
                        <div className="list-group-item d-flex align-items-center">
                          <div className="flex-1 text-break">
                            <div className="col-md-4 d-flex">{imageVisible ? <ImageHandler url={userData.imagePath} /> : ""}</div>
                          </div>
                          <div>
                            <a href="#/" onClick={() => setModalImage({ show: true, data: null })} className="btn btn-outline-default w-100px">
                              Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <div className="spinner-border"></div>
                  )}
                </div>
              </div>

              <div className="col-xl-3"></div>
            </div>
          </div>
        </div>
      </div>

      <ModalEditProfileName show={modalName.show} data={modalName.data} submit={(val) => handelSubmitName(val)} onHide={() => setModalName({ ...modalName, show: false })} />
      <ModalEditProfilePhoneNumber show={modalPhone.show} data={modalPhone.data} submit={(val) => handelSubmitPhone(val)} onHide={() => setModalPhone({ ...modalPhone, show: false })} />
      <ModalEditProfilePassword error={modalPass.error} show={modalPass.show} data={modalPass.data} submit={(val) => handelSubmitPass(val)} onHide={() => setModalPass({ ...modalPass, show: false })} />
      <ModalEditProfileImage show={modalImage.show} data={modalImage.data} submit={(val) => handelSubmitImage(val)} onHide={() => setModalImage({ ...modalImage, show: false })} />
      <ModalLoading show={modalLoading} value={progressValue} onHide={() => setModalLoading(false)} title={"Uploading..."} text={"Please wait uploading is on progress..."} />
    </div>
  );
}

function ImageHandler({ url }) {
  const [image, setImage] = useState();
  async function toBlob(base64) {
    const blob = await base64ToBlob(base64);
    setImage(URL.createObjectURL(blob));
  }
  useEffect(() => {
    axiosInstance
      .get(url, getHeader())
      .then((res) => {
        toBlob(res.data.image);
        // setImage(res.data.image);
      })
      .catch((e) => {});
  }, [url]);
  return (
    <>
      <img className="img-thumbnail" src={image} alt="" />
    </>
  );
}

export default Settings;
