import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "../../components/card/card";
import { useEffect, useState } from "react";

function BarCartH(value) {
  var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();
  return {
    chart: {
      type: "bar",
    },
    colors: ["rgba(" + themeColorRgb],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "40%",
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val + "%";
      },
    },
    series: value,
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        showDuplicates: false,
        align: "right",
        minWidth: 0,
        maxWidth: 160,

        style: {
          colors: "#ffffff",
          fontSize: "15px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
  };
}
function MainChart(value) {
  var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();
  return {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
      },
    },
    series: value,
    labels: ["Open", "Close"],
    colors: ["rgba(" + themeColorRgb + ", .15)", "rgba(" + themeColorRgb + ", 1)"],
    stroke: {
      show: false,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: "65%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "24px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: "#ffffff",
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "Value",
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ffffff",
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce((a, b) => {
                    return b;
                  }, 0) + "%"
                );
              },
            },
          },
        },
      },
    },
  };
}
const presetMainRead = {
  title: "blank",
  value: [],
};
const presetByAreaRead = {
  title: "blank",
  value: [],
};
function AllReadPrec({ data }) {
  const [mainReadiness, setMainReadiness] = useState(presetMainRead);
  const [areaRead, setAreaRead] = useState(presetByAreaRead);
  const [qtyMechByArea, setQtyMechByArea] = useState([]);
  function readTrainingClick() {
    const readTr = trainingReadiness({ mode: "all" }, data);
    setMainReadiness({ title: "Training Readiness", value: [100 - readTr.readiness, readTr.readiness] });
  }
  function readCompetencyClick() {
    const readComp = competencyReadiness({ mode: "all" }, data);
    setMainReadiness({ title: "Competency Readiness", value: [100 - readComp.readiness, readComp.readiness] });
  }
  function readAllClick() {
    const readTr = trainingReadiness({ mode: "all" }, data);
    const readComp = competencyReadiness({ mode: "all" }, data);
    const read = mechanicReadiness(readTr.readiness, readComp.readiness);
    setMainReadiness({ title: "Mechanic Readiness", value: [100 - read, read] });
  }

  // --------------------------------------------//-----------------------------------
  function readAllByAreaClick() {
    const result = mechanicReadinessByArea(data).map((val) => {
      return {
        x: val.area,
        y: val.readiness,
      };
    });
    setAreaRead({
      title: "Mechanic Readiness",
      value: [
        {
          data: result,
        },
      ],
    });
  }
  function readTrainingByAreaClick() {
    const result = trainingReadiness({ mode: "byArea" }, data.readinessTraining.bySection).map((val) => {
      return {
        x: val.area,
        y: val.readiness,
      };
    });
    setAreaRead({
      title: "Training Readiness",
      value: [
        {
          data: result,
        },
      ],
    });
  }
  function readCompetencyByAreaClick() {
    const result = competencyReadiness({ mode: "byArea" }, data.readinessCompetency.bySection).map((val) => {
      return {
        x: val.area,
        y: val.readiness,
      };
    });
    setAreaRead({
      title: "Competency Readiness",
      value: [
        {
          data: result,
        },
      ],
    });
  }

  function mechanicReadiness(trainingRead, competencyRead) {
    return parseFloat(((trainingRead + competencyRead) / 2).toFixed(2));
  }

  function qtyMechanicByArea(value) {
    setQtyMechByArea(value);
  }
  function mechanicReadinessByArea(value) {
    const trnRead = trainingReadiness({ mode: "byArea" }, value.readinessTraining.bySection);
    const compRead = competencyReadiness({ mode: "byArea" }, value.readinessCompetency.bySection);

    const mechRead = compRead.map((val) => {
      const trnR = trnRead.filter((trn) => {
        if (trn.area == val.area) return trn;
      });
      return {
        area: val.area,
        readiness: parseFloat(((val.readiness + trnR[0].readiness) / 2).toFixed(2)),
      };
    });

    return mechRead.sort((a, b) => b.readiness - a.readiness);
  }
  function trainingReadiness(option, value) {
    switch (option.mode) {
      case "all":
        return value.readinessTraining.all;
      case "byArea":
        const area = getArea(value);
        const result = area.map((area, i) => {
          const filterd = value.filter((value) => {
            return value.area == area;
          });
          let open = 0;
          let close = 0;
          filterd.forEach((val) => {
            open += val.open;
            close += val.close;
          });
          return {
            area: area,
            open: open,
            close: close,
            readiness: parseFloat(((close / (open + close)) * 100).toFixed(2)),
          };
        });

        return result.sort((a, b) => b.readiness - a.readiness);
    }
  }
  function competencyReadiness(option, value) {
    switch (option.mode) {
      case "all":
        return value.readinessCompetency.all;
      case "byArea":
        const area = getArea(value);
        const result = area.map((area, i) => {
          const filterd = value.filter((value) => {
            return value.area == area;
          });
          let open = 0;
          let close = 0;
          filterd.forEach((val) => {
            open += val.open;
            close += val.close;
          });
          return {
            area: area,
            open: open,
            close: close,
            readiness: parseFloat(((close / (open + close)) * 100).toFixed(2)),
          };
        });

        return result.sort((a, b) => b.readiness - a.readiness);
    }
  }

  function getArea(value) {
    let area = [];
    value.forEach((val) => {
      if (area.indexOf(val.area) == -1) area.push(val.area);
    });
    return area;
  }
  function prosentase(value, current) {
    let sum = 0;
    value.forEach((val) => {
      sum += val.qty;
    });

    return ((current / sum) * 100).toFixed(2);
  }
  useEffect(() => {
    const readTr = trainingReadiness({ mode: "all" }, data);
    const readComp = competencyReadiness({ mode: "all" }, data);
    const read = mechanicReadiness(readTr.readiness, readComp.readiness);
    setMainReadiness({ title: "Mechanic Readinss", value: [100 - read, read] });
    const result = mechanicReadinessByArea(data).map((val) => {
      return {
        x: val.area,
        y: val.readiness,
      };
    });
    qtyMechanicByArea(data.qtyMechByArea);
    setAreaRead({
      title: "Mechanic Readiness",
      value: [
        {
          data: result,
        },
      ],
    });
  }, [data]);
  return (
    <>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <Card>
            <CardBody>
              <div className="dropdown-toggle">
                <a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-75 text-decoration-none">
                  <i className="far fa-thumbs-up fa-fw fa-lg text-theme me-1"></i> {mainReadiness.title}
                </a>
                <div className="dropdown-menu">
                  <a href="#/" onClick={readAllClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Mechanic Readinss
                  </a>
                  <a href="#/" onClick={readTrainingClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Training Readiness
                  </a>
                  <a href="#/" onClick={readCompetencyClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Competency readiness
                  </a>
                </div>
              </div>
              <ReactApexChart type="donut" series={MainChart(mainReadiness.value).series} options={MainChart(mainReadiness.value)} />
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-6 mb-3">
          <Card>
            <CardBody>
              <div className="dropdown-toggle">
                <a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-75 text-decoration-none">
                  <i className="far fa-thumbs-up fa-fw fa-lg text-theme me-1"></i> {areaRead.title}
                </a>
                <div className="dropdown-menu">
                  <a href="#/" onClick={readAllByAreaClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Mechanic Readinss
                  </a>
                  <a href="#/" onClick={readTrainingByAreaClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Training Readiness
                  </a>
                  <a href="#/" onClick={readCompetencyByAreaClick} className="dropdown-item">
                    <i className="fas fa-angle-double-right fa-fw fa-lg text-theme me-1"></i> Competency readiness
                  </a>
                </div>
              </div>
              <ReactApexChart type="bar" series={BarCartH(areaRead.value).series} options={BarCartH(areaRead.value)} />
              <div className="col-lg-6 mb-lg-0">
                <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                  <thead>
                    <tr className="text-inverse text-opacity-75">
                      <th className="w-50">AREAS</th>
                      <th className="w-25 text-end">QUANTITY</th>
                      <th className="w-25 text-end">PCQ%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qtyMechByArea.length > 0
                      ? qtyMechByArea.map((val, index) => (
                          <tr key={index}>
                            <td>{val.jobArea}</td>
                            <td className="text-end text-theme">{val.qty}</td>
                            <td className="text-end">{prosentase(qtyMechByArea, val.qty) + "%"}</td>
                          </tr>
                        ))
                      : "No Data"}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AllReadPrec;
