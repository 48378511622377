import App from "./../app.jsx";

import Dashboard from "./../pages/dashboard/dashboard.js";

import PagesError from "./../pages/pages/error.js";

import MpDetail from "../pages/manpower/profile.jsx";

import Logingin from "../pages/logingin/login.js";

import AppLogin from "../appLogin.jsx";
import Kpi from "../pages/kpi/kpi.jsx";
import Data from "../pages/manpower/data.jsx";
import NewManpower from "../pages/manpower/new.jsx";
import EditManpower from "../pages/manpower/edit.jsx";
import DataTraining from "../pages/training/data.jsx";
import DetailTraining from "../pages/training/detail.jsx";
import TrainingReport from "../pages/training/report.jsx";
import ReportEvent from "../pages/training/reportEvent.jsx";
import ReportParticipant from "../pages/training/reportParticipant.jsx";
import ReportDocument from "../pages/training/reportDocument.jsx";
import DataMentoring from "../pages/mentoring/data.jsx";
import DetailMentoring from "../pages/mentoring/detail.jsx";
import MentoringReport from "../pages/mentoring/report.jsx";
import MentorReportEvent from "../pages/mentoring/reportEvent.jsx";
import MentorReportDocument from "../pages/mentoring/reportDocument.jsx";
import TrainingPlanData from "../pages/trainingPlan/data.jsx";
import PlanTrainingDetail from "../pages/trainingPlan/detail.jsx";
import PlanTrainingNewReport from "../pages/trainingPlan/report.jsx";
import QuestionData from "../pages/question/data.jsx";
import QuestionDetail from "../pages/question/detail.jsx";
import EventData from "../pages/question/event.jsx";
import EventDetail from "../pages/question/eventDetail.jsx";
import EventResult from "../pages/question/eventResult.jsx";
import PagesLogin from "../pages/login/login.jsx";
import AppProtected from "../appProtected.jsx";
import Settings from "../pages/profile/settings.js";
import Logingout from "../pages/logout/logingout.js";
import RegulationSOPData from "../pages/regulations/data-SOP.jsx";

const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [{ path: "login", element: <PagesLogin /> }],
  },
  {
    path: "*",
    element: <AppProtected />,
    children: [
      // { path: '', element: <Navigate to='/dashboard' /> },
      { path: "", element: <Logingin /> },
      { path: "logingout", element: <Logingout /> },
      { path: "kpi", element: <Kpi /> },
      { path: "dashboard", element: <Dashboard /> },
      {
        path: "manpower/*",
        children: [
          { path: "data", element: <Data /> },
          { path: "detail/:id", element: <MpDetail /> },
          { path: "new", element: <NewManpower /> },
          { path: "edit/:id", element: <EditManpower /> },
        ],
      },
      {
        path: "training/*",
        children: [
          { path: "data", element: <DataTraining /> },
          { path: "detail/:id", element: <DetailTraining /> },
          { path: "plan/data", element: <TrainingPlanData /> },
          { path: "plan/detail/:id", element: <PlanTrainingDetail /> },
          { path: "plan/new", element: <PlanTrainingNewReport /> },
          {
            path: "report/",
            element: <TrainingReport />,
            children: [
              { path: "event", element: <ReportEvent /> },
              { path: "participant", element: <ReportParticipant /> },
              { path: "document", element: <ReportDocument /> },
            ],
          },
        ],
      },
      {
        path: "mentoring/*",
        children: [
          { path: "data", element: <DataMentoring /> },
          { path: "detail/:id", element: <DetailMentoring /> },
          {
            path: "report/",
            element: <MentoringReport />,
            children: [
              { path: "event", element: <MentorReportEvent /> },
              { path: "document", element: <MentorReportDocument /> },
            ],
          },
        ],
      },
      {
        path: "question/*",
        children: [
          { path: "data", element: <QuestionData /> },
          { path: "event", element: <EventData /> },
          { path: "detail/:id", element: <QuestionDetail /> },
          { path: "event-detail/:id", element: <EventDetail /> },
          { path: "event-result/:id", element: <EventResult /> },
        ],
      },
      {
        path: "regulations/*",
        children: [{ path: "sop", element: <RegulationSOPData /> }],
      },
      {
        path: "profile/",
        children: [{ path: "setting", element: <Settings /> }],
      },
    ],
  },
  {
    path: "*",
    element: <AppLogin />,
    children: [{ path: "*", element: <PagesError /> }],
  },
];

export default AppRoute;
