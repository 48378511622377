import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "./../../config/app-settings.js";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import getHeader from "../../config/Headers.js";

function Logingout() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  function logoutHandler() {
    axiosInstance
      .post("/logoutV2", {}, getHeader())
      .then((res) => {
        sessionStorage.removeItem("nuzulul");
        navigate("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    logoutHandler();
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, []);

  return (
    <div className="login">
      <div className="login-content">
        <h1 className="text-center">LOGING OUT</h1>
        <div className="text-inverse text-opacity-50 text-center mb-4">Please Wait....</div>

        <div className="text-center text-inverse text-opacity-50">Plant People Development Jobsite Maruwai Coal</div>
      </div>
    </div>
  );
}

export default Logingout;
