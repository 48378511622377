import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slideToggle } from "./../../composables/slideToggle.js";
import getHeader from "../../config/Headers.js";
import axiosInstance from "../../config/axios.js";

function Header({ user }) {
  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector(".app");
    if (elm) {
      if (!(elm.classList.contains("app-with-top-nav") && elm.classList.contains("app-without-sidebar"))) {
        elm.classList.toggle("app-sidebar-collapsed");
      }
    }
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    if (elm) {
      if (!(elm.classList.contains("app-with-top-nav") && elm.classList.contains("app-without-sidebar"))) {
        elm.classList.toggle("app-sidebar-mobile-toggled");
      } else {
        slideToggle(document.querySelector(".app-top-nav"));
      }
    }
  };

  const toggleAppHeaderSearch = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-header-menu-search-toggled");
  };

  return (
    <div id="header" className="app-header">
      <div className="desktop-toggler">
        <button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="mobile-toggler">
        <button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="brand">
        <Link to="#" className="brand-logo">
          <img src="assets/img/logo/ppd.png" alt="Profile" height="30" />
          <span className="brand-text">PPD MACO</span>
        </Link>
      </div>

      <div className="menu">
        <div className="menu-item dropdown dropdown-mobile-full">
          <a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
            <div className="menu-img online">
              <ImageHandler url={user.imagePath} />
            </div>
            <div className="menu-text d-sm-block d-none w-170px">{user.name}</div>
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
            <Link to="/profile/setting" className="dropdown-item d-flex align-items-center">
              SETTINGS <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <div className="dropdown-divider"></div>
            <Link to={"/logingout"} className="dropdown-item d-flex align-items-center">
              LOGOUT <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
            </Link>
          </div>
        </div>
      </div>

      <form className="menu-search" method="POST" name="header_search_form">
        <div className="menu-search-container">
          <div className="menu-search-icon">
            <i className="bi bi-search"></i>
          </div>
          <div className="menu-search-input">
            <input type="text" className="form-control form-control-lg" placeholder="Search menu..." />
          </div>
          <div className="menu-search-icon">
            <a href="#/" onClick={toggleAppHeaderSearch}>
              <i className="bi bi-x-lg"></i>
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}
export default Header;

function ImageHandler({ url }) {
  const [image, setImage] = useState();
  useEffect(() => {
    axiosInstance
      .get(url, getHeader())
      .then((res) => {
        setImage(res.data.image);
      })
      .catch((e) => {});
  }, [url]);
  return (
    <>
      <img className="img-thumbnail" src={image} alt="" />
    </>
  );
}
