import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import ApexChart from "../../components/chart/apexChart.jsx";
import ModalLoadingPage from "../../components/modal/loadingPage.jsx";
import ApexChartOneData from "../../components/chart/apexChartOneData.jsx";
import PyramidChart from "../../components/chart/pyramidChart.jsx";
import axiosInstance from "../../config/axios.js";
import getHeader from "../../config/Headers.js";
import AllReadPrec from "./allReadPrc.js";

function Dashboard() {
  // server chart
  const [statsData, setStatsData] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [countryData, setCountryData] = useState();
  const [sourceData, setSourceData] = useState();

  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [xAxisReadChart, setXaxisReadChart] = useState([]);
  const [readChartData, setReadChartData] = useState(false);

  const [pyramid, setPyramid] = useState(false);

  const [xAxixManpowerLogChart, setXAxixManpowerLogChart] = useState([]);
  const [logManpowerData, setLogManpowerData] = useState(false);

  const [loadingModal, setLoadingModal] = useState({ show: true, hide: false });

  const [allResumeData, setAllReasumeData] = useState();

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body).getPropertyValue("--bs-theme").trim();
    var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: ["rgba(" + themeColorRgb + ", 1)", "rgba(" + themeColorRgb + ", .75)", "rgba(" + themeColorRgb + ", .5)"],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        // colors: ['rgba('+ themeColorRgb + ', .15)', 'rgba('+ themeColorRgb + ', .35)', 'rgba('+ themeColorRgb + ', .55)', 'rgba('+ themeColorRgb + ', .75)', 'rgba('+ themeColorRgb + ', .95)'],
        colors: ["rgba(" + themeColorRgb + ", .15)", themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  useEffect(() => {
    axiosInstance
      .get("/stats", getHeader())
      .then((res) => {
        setStatsData(res.data.data);
      })
      .catch(() => setStatsData([]));
    axiosInstance
      .get("/server", getHeader())
      .then((res) => {
        setReadChartData(res.data.series);
        setXaxisReadChart(res.data.category);
      })
      .catch(() => {
        setReadChartData([]);
        setXaxisReadChart([]);
      });
    axiosInstance.get("/get-dashboard-readiness-mechanic/resume", getHeader()).then((res) => {
      setAllReasumeData(res.data.data);
    });
    axiosInstance
      .get("/resumeRead", getHeader())
      .then((res) => {
        setServerData(res.data.data);
        setLoadingModal({ show: false, hide: false });
      })
      .catch(() => {
        setServerData([]);
      });
    axiosInstance.get("/gradeqty", getHeader()).then((res) => {
      setSourceData(res.data.data.listGrade);
      setPyramid(res.data.data.pyramid);
    });
    axiosInstance.get("/qtymanpowerlog", getHeader()).then((res) => {
      setLogManpowerData(res.data.data.series);
      setXAxixManpowerLogChart(res.data.data.xAxis);
    });
    axiosInstance.get("/highlvl", getHeader()).then((res) => {
      setCountryData(res.data.data);
    });
    setLoadingModal({ show: true, hide: false });

    document.addEventListener("theme-reload", () => {
      setChartOptions(getChartOptions());
    });
  }, []);

  return (
    <>
      <div>
        <p>
          Following are the results of mechanical development achievements in real time. data taken from the{" "}
          <a href="https://ppdmaco.com" target="_blank" rel="noreferrer">
            ppdmaco.com
          </a>{" "}
          database system. All calculations are outside of the apprentice mechanic / MPP.
        </p>
        {allResumeData ? <AllReadPrec data={allResumeData} /> : ""}
        <div className="row">
          {statsData &&
            statsData.length > 0 &&
            statsData.map((stat, index) => (
              <div className="col-xl-3 col-lg-6" key={index}>
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{stat.title}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div className="col-7">
                        <h3 className="mb-0">{stat.total}</h3>
                      </div>
                      <div className="col-5">
                        <div className="mt-n2">
                          <Chart type={stat.chartType} height={stat.chartHeight} options={chartOptions[stat.chartType]} series={stat.chartData} />
                        </div>
                      </div>
                    </div>
                    <div className="small text-inverse text-opacity-50 text-truncate">
                      {stat.info.length > 0 &&
                        stat.info.map((info, index) => (
                          <div key={index}>
                            <i className={info.icon}></i> {info.text}
                          </div>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
        </div>
        <div className="row">
          <div className="col-xl-6">
            {" "}
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">MECHANIC READINESS</span>
                  <CardExpandToggler />
                </div>
                <div className="ratio ratio-21x9 mb-3">{readChartData ? <ApexChart type="bar" height="100%" series={readChartData} xLable={xAxisReadChart} /> : ""}</div>
                <div className="row">
                  {serverData && serverData.length > 0 ? (
                    serverData.map((server, index) => (
                      <div className="col-lg-6 mb-3 mb-lg-0" key={index}>
                        <div className="d-flex">
                          <div className="w-50px pt-3">
                            <Chart type={server.chartType} height={server.chartHeight} options={chartOptions[server.chartType]} series={server.chartData} />
                          </div>
                          <div className="ps-3 flex-1">
                            <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">{server.name}</div>
                            <div className="mb-2 fs-5 text-truncate">{server.total}</div>
                            <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                              <div className="progress-bar bg-theme" style={{ width: server.progress }}></div>
                            </div>
                            <div className="fs-11px text-inverse text-opacity-50 mb-4 text-truncate">{server.time}</div>
                            <div className="fs-11px text-danger text-opacity-50 mb-1 text-truncate">{server.status}</div>
                            {server.info.length > 0 ? (
                              server.info.map((info, index) => (
                                <div className="d-flex align-items-center small" key={index}>
                                  <i className={"bi bi-circle-fill fs-6px me-2 " + info.class}></i>
                                  <div className="flex-1">{info.title}</div>
                                  <div>{info.value}</div>
                                </div>
                              ))
                            ) : (
                              <div>No records found</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12">No records found</div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-xl-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">MECHANIC QUANTITY</span>
                  <CardExpandToggler />
                </div>
                <div className="ratio ratio-21x9 mb-3">{logManpowerData ? <ApexChartOneData type="area" height="100%" series={logManpowerData} xLable={xAxixManpowerLogChart} /> : ""}</div>
                <div className="row gx-4">
                  <div className="col-xxl-6 mb-3 mb-lg-0">
                    <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                      <thead>
                        <tr className="text-inverse text-opacity-75">
                          <th className="w-70">SUB. SECT.</th>
                          <th className="w-20 text-end">HI LVL.</th>
                          <th className="w-10 text-end">QTY.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {countryData && countryData.length > 0 ? (
                          countryData.map((country, index) => (
                            <tr key={index} className={country.class}>
                              <td>{country.name}</td>
                              <td className="text-end">{country.visits}</td>
                              <td className="text-end">{country.pct}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-xxl-6">
                    <Card>
                      <CardBody className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="w-130px">{!pyramid ? "" : <PyramidChart type="bar" series={pyramid.series} height={125} xAxis={pyramid.xAxis} />}</div>
                          <div className="flex-1 ps-2">
                            <table className="w-100 small mb-0 text-inverse text-opacity-60">
                              <tbody>
                                {sourceData && sourceData.length > 0 ? (
                                  sourceData.map((source, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className={"w-6px h-6px rounded-pill me-2 " + source.class}></div>
                                          {source.name}
                                        </div>
                                      </td>
                                      <td className="text-end">{source.percentage + "%"}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="2">No records found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ModalLoadingPage show={loadingModal.show} hide={loadingModal.hide} />
    </>
  );
}

export default Dashboard;
