const config = {
  // mainURL: "http://127.0.0.1:8000", //dev
  mainURL: "https://singspro.ppdmaco.com", //prod

  // baseURL: "http://127.0.0.1:8000/api", //dev
  baseURL: "https://singspro.ppdmaco.com/api", //prod

  // apkUrl: "http://localhost:3000/", //dev
  apkUrl: "https://ppdmaco.com/", //prod
};

export default config;
