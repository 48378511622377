import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "react-bootstrap";

function ModalEditProfilePassword({ show, onHide, submit, error = { old: [], new1: [], new2: [] }, data = { id: null, val: null } }) {
  const [password, setPassword] = useState({ old: "", new1: "", new2: "" });
  const [matchPass, setMatchPass] = useState({ status: true, message: "" });
  const [submitEnable, setSubmitEnable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  function handleOnHide() {
    setSpinner(false);
    setPassword({ old: "", new1: "", new2: "" });
    setMatchPass({ status: true, message: "" });
    onHide();
  }

  function handleClickSubmit(val) {
    setSpinner(true);
    if (val.new1 != val.new2) {
      setSpinner(false);
      setMatchPass({ status: false, message: "Password not match !!" });
      return;
    }
    if (val.new1.length < 8) {
      setSpinner(false);
      setMatchPass({ status: false, message: "Password min 8 character !!" });
      return;
    }
    if (val.new1 == val.old) {
      setSpinner(false);
      setMatchPass({ status: false, message: "The new password cannot be the same as the old password " });
      return;
    }

    setPassword({ old: "", new1: "", new2: "" });
    submit({ id: data.id, val: val });
  }

  useEffect(() => {
    if (password.old && password.new2 && password.new1) {
      setSubmitEnable(true);
    } else {
      setSubmitEnable(false);
    }
  }, [password]);
  useEffect(() => {
    if (error.old.length > 0 || error.new1.length > 0 || error.new2.length > 0) {
      setSpinner(false);
      setSubmitEnable(true);
    }
  }, [error]);
  return (
    <>
      <Modal show={show} onHide={() => handleOnHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit your password</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label className="form-label">Enter old password {error.old.length > 0 ? <span className="text-danger">{error.old}</span> : ""}</label>
            <input type="password" onChange={(e) => setPassword({ ...password, old: e.target.value })} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Enter new password</label>
            <input type="password" onChange={(e) => setPassword({ ...password, new1: e.target.value })} className={matchPass.status ? "form-control" : "form-control is-invalid"} />
          </div>
          <div className="mb-3">
            <label className="form-label">Retype new password {matchPass.status ? "" : <span className="text-danger">{matchPass.message}</span>}</label>
            <input type="password" onChange={(e) => setPassword({ ...password, new2: e.target.value })} className={matchPass.status ? "form-control" : "form-control is-invalid"} />
          </div>
        </ModalBody>
        <ModalFooter>
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <button disabled={submitEnable ? false : true} type="button" onClick={() => handleClickSubmit(password)} className="btn btn-outline-theme">
              Save changes
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}
export default ModalEditProfilePassword;
