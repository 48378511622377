import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Pagination2 from "../../components/pagination/pagination2.jsx";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import axiosInstance2 from "../../config/axios2.js";
import DateFormat from "../../components/dateFormater/dateFormat1.js";
import getHeader from "../../config/Headers.js";
import ModalLoading from "../../components/modal/modalLoading.jsx";
import ModalRegulationNew from "../../components/modal/modalRegulationNew.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalRegulationEdit from "../../components/modal/modalRegulationEdit.jsx";
import ModalConfirmMessage from "../../components/modal/modalConfirmMessage.jsx";

const trainingPresetData = {
  current_page: "",
  data: [],
  first_page_url: "",
  from: "",
  last_page: "",
  last_page_url: "",
  links: [],
  next_page_url: "",
  path: "",
  per_page: 3,
  prev_page_url: "",
  to: "",
  total: "",
};

function RegulationSOPData() {
  const [regAllData, setRegAllData] = useState(trainingPresetData);
  const [modalLoading, setModalLoading] = useState({ show: false, value: 0 });
  const [loadingValue, setLoadingValue] = useState(0);
  const [modalLoadingSubmit, setModalLoadingSubmit] = useState(false);
  const [modalLoadingSubmitEdit, setModalLoadingSubmitEdit] = useState(false);
  const [modalLoadingSubmitValue, setModalLoadingSubmitValue] = useState(0);
  const [modalLoadingSubmitEditValue, setModalLoadingSubmitEditValue] = useState(0);
  const [search1Value, setSearch1Value] = useState("");
  const [miniResume, setMiniResume] = useState(null);
  const [modalNew, setModalNew] = useState(false);
  const [modalDelConfirm, setModalDelConfirm] = useState({ show: false, id: null });
  const [modalEdit, setModaledit] = useState({ show: false, id: null });
  const [modalSubmitFailed, setModalSubmitFailed] = useState(false);
  const navigate = useNavigate();

  function handleClickNewRegulation() {
    setModalNew(true);
  }

  function handleSubmitNew(val) {
    setModalNew(false);
    setModalLoadingSubmit(true);
    axiosInstance
      .post("/regulation-new/new", val, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setModalLoadingSubmitValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        if (res.data.status == "notValid") {
          setModalLoadingSubmit(false);
          setModalSubmitFailed(true);
        } else {
          setModalLoadingSubmit(false);
          getAllRegulationData();
        }
      });
  }

  function handleClickEdit(id) {
    setModaledit({ show: true, id: id });
  }
  function handleSubmitEdit(val) {
    setModaledit(false);
    setModalLoadingSubmitEdit(true);
    axiosInstance
      .post("/regulation-edit/edit-one", val, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setModalLoadingSubmitEditValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "notValid") {
          setModalLoadingSubmitEdit(false);
          setModalSubmitFailed(true);
        } else {
          getAllRegulationData();
          setModalLoadingSubmitEdit(false);
        }
      });
  }
  function handleClickDelete(id) {
    setModalDelConfirm({ show: true, id: id });
  }

  function handleDeleteConfirm(id) {
    setModalDelConfirm({ show: false, id: id });
    axiosInstance
      .delete("/regulation-delete/" + id, getHeader())
      .then(() => {
        getAllRegulationData();
      })
      .catch(() => {});
  }

  function nextClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setRegAllData(res.data))
      .catch((e) => {});
  }
  function prevClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setRegAllData(res.data))
      .catch((e) => {});
  }
  function submitSearch1(e) {
    e.preventDefault();
    axiosInstance
      .get("/regulation-get/all-data?search=" + search1Value, getHeader())
      .then((res) => setRegAllData(res.data))
      .catch((e) => {});
  }

  async function saveFile(res) {
    const text = await res.data.text();
    const json = JSON.parse(text);

    const binaryString = atob(json.file);
    const byteNumbers = new Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: json.mimeType });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", json.fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  function handleClickDownload(path) {
    setModalLoading({ ...modalLoading, title: "Downloading", text: "Please wait downloading in proggress..", show: true });
    axiosInstance
      .get(path, {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onDownloadProgress: (e) => {
          setLoadingValue(Math.round((e.loaded / e.total) * 100));
        },
        responseType: "blob",
        validateStatus: false,
      })
      .then((res) => {
        saveFile(res, "fileName");
        setModalLoading({ ...modalLoading, show: false });
      })
      .catch((e) => {});
  }
  function getAllRegulationData() {
    axiosInstance
      .get("/regulation-get/all-data", getHeader())
      .then((res) => setRegAllData(res.data))
      .catch((e) => {});
  }
  useEffect(() => {
    getAllRegulationData();
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">REGULATIONS</a>
              </li>
              <li className="breadcrumb-item active">Data</li>
            </ul>

            <h1 className="page-header">
              Company Regulation Data <small></small>
            </h1>
          </div>

          <div className="ms-auto">
            <a onClick={() => handleClickNewRegulation()} href="#/" className="btn btn-outline-theme">
              <i className="fa fa-plus-circle fa-fw me-1"></i> New
            </a>
          </div>
        </div>

        <hr className="mb-4" />
        <p>
          <a href="https://id.wikipedia.org/wiki/Tata_kelola_perusahaan" target="blank">
            <i>Company regulation</i>
          </a>{" "}
          atau peraturan perusahaan adalah kumpulan aturan yang dibuat secara tertulis oleh pengusaha untuk mengatur perilaku dan interaksi karyawan dan manajemen perusahaan, pada bagian ini berisi semua peraturan-peraturan yang pernah
          dibuat di dalam perusahaan meliputi:
        </p>
        <ul>
          <li>SOP</li>
          <li>INK</li>
          <li>STD</li>
          <li>SK Direksi</li>
          <li>Internal Memo</li>
        </ul>
        <p>
          Silahkan upload dokumen yang tersebut diatas dengan memberikan <span className="text-theme">tag</span> pada setiap dokumen agar mempermudah kita dalam mencari dokumen saat kita membutuhkannya.
        </p>
        <Card>
          <div className="tab-content p-4">
            {/* ----------------All Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade show active" id="allTab">
              <form onSubmit={(e) => submitSearch1(e)}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch1Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">Reg. Number</th>
                      <th className="border-top-0 pt-0 pb-2">Document Title</th>
                      <th className="border-top-0 pt-0 pb-2">Uploaded/Edited</th>
                      <th className="border-top-0 pt-0 pb-2">Date</th>
                      <th className="border-top-0 pt-0 pb-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {regAllData.data.length > 0 ? (
                      regAllData.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">{value.documentNumber}</td>
                          <td className="align-middle">{value.documentTitle}</td>
                          <td className="align-middle">{value.instructor.namaInstructor}</td>
                          <td className="align-middle">{DateFormat(value.created_at)}</td>
                          <td className="align-middle">
                            <a onClick={() => handleClickEdit(value.id)} href="#/" className="mx-2">
                              <i className="fas fa-edit"></i>
                            </a>
                            <a onClick={() => handleClickDownload(value.filePath)} href="#/" className="mx-2">
                              <i className="fas fa-download"></i>
                            </a>
                            <a onClick={() => handleClickDelete(value.id)} href="#/">
                              <i className="text-danger fas fa-trash"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {regAllData.data.length > 0 ? <Pagination2 pageInfo={regAllData} nextClick={(val) => nextClick1(val)} prevClick={(val) => prevClick1(val)} /> : ""}
            </div>
            {/* ----------------End All Tab------------------------------------------------------------------ */}
          </div>
        </Card>
      </div>
      <ModalLoading show={modalLoading.show} title={modalLoading.title} text={modalLoading.text} value={loadingValue} onHide={() => setModalLoading({ ...modalLoading, show: false })} />
      <ModalLoading show={modalLoadingSubmit} title={"Uploading..."} text={"Please wait guys..."} value={modalLoadingSubmitValue} onHide={() => setModalLoadingSubmit(false)} />
      <ModalLoading show={modalLoadingSubmitEdit} title={"Uploading..."} text={"Please wait guys..."} value={modalLoadingSubmitEditValue} onHide={() => setModalLoadingSubmitEdit(false)} />
      <ModalRegulationNew show={modalNew} onHide={() => setModalNew(false)} submit={(e) => handleSubmitNew(e)} />
      <ModalConfirmMessage
        show={modalSubmitFailed}
        theme={"danger"}
        title={"Failed"}
        text={"Upload failed kemungkinan ada file yang tidak sesuai atau ada bagian yang belum diisi"}
        onHide={() => setModalSubmitFailed(false)}
        clicked={() => setModalSubmitFailed(false)}
      />
      <ModalConfirm
        show={modalDelConfirm.show}
        onHide={() => setModalDelConfirm({ ...modalDelConfirm, show: false })}
        title={"Warning"}
        text={"Are you sure want to delete this regulation ??"}
        theme={"danger"}
        id={modalDelConfirm.id}
        clicked={(e) => handleDeleteConfirm(e)}
      />
      <ModalRegulationEdit show={modalEdit.show} id={modalEdit.id} onHide={() => setModaledit({ ...modalEdit, show: false })} submit={(val) => handleSubmitEdit(val)} />
    </>
  );
}

export default RegulationSOPData;
