import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import getHeader from "../../config/Headers";
import { base64ToBlob } from "file64";

function ImageHandler({ url }) {
  const [blob, setBlob] = useState();
  async function toBlob(base64) {
    const blob = await base64ToBlob(base64);
    setBlob(URL.createObjectURL(blob));
  }
  useEffect(() => {
    axiosInstance
      .get(url, getHeader())
      .then((res) => {
        toBlob(res.data);
        // setBlob(res.data);
      })
      .catch((e) => {});
  }, [url]);

  return (
    <>
      <img src={blob} className="img-thumbnail" alt="QImage"></img>
    </>
  );
}
export default ImageHandler;
