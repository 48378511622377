import { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";
import axiosInstance from "../../config/axios";
import getHeader from "../../config/Headers";

function reducer(state, action) {
  switch (action.type) {
    case "documentNumber":
      return { ...state, documentNumber: action.value };
    case "documentTitle":
      return { ...state, documentTitle: action.value };
    case "content":
      return { ...state, content: action.value };
  }
}
const initialValue = {
  documentNumber: "",
  documentTitle: "",
  content: "",
};

const suggestions = [
  {
    id: "1",
    text: "Thailand",
    className: "",
  },
];
function ModalRegulationEdit({ show, onHide, id, submit }) {
  // const [tags, setTags] = useState([{ id: "Thailand", text: "Thailand", className: "text-theme" }]);
  const [values, dispatch] = useReducer(reducer, initialValue);
  const [tags, setTags] = useState([]);
  const [file, setFile] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [suggest, setSuggest] = useState(suggestions);
  const [loadingPage, setLoadingPage] = useState(true);

  function submitClick() {
    let data = values;
    data = { ...data, tags: tags };
    let formData = new FormData();
    formData.append("regulation", JSON.stringify(data));
    formData.append("id", id);
    formData.append("file", file);
    submit(formData);
  }
  function getData(idReg) {
    axiosInstance
      .get("/regulation-get/one-data/" + idReg, getHeader())
      .then((res) => {
        setDataPreset(res.data);
      })
      .catch(() => {});
  }

  function setDataPreset(presetData) {
    const pressetTag = presetData.tags.map((val, ind) => {
      return {
        id: val.tagText,
        text: val.tagText,
        className: "",
      };
    });
    dispatch({ type: "documentNumber", value: presetData.documentNumber });
    dispatch({ type: "documentTitle", value: presetData.documentTitle });
    dispatch({ type: "content", value: presetData.content });
    setTags(pressetTag);
  }
  function getSelectorTagSuggest() {
    axiosInstance
      .get("/optionselector/tag-suggest", getHeader())
      .then((res) => {
        setSuggest(res.data);
        setLoadingPage(false);
      })
      .catch(() => {});
  }

  function onShow() {
    setLoadingPage(true);
    getSelectorTagSuggest();
    getData(id);
  }

  // ------------------------------------------------------//---------------------------------------------------
  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const onTagUpdate = (index, newTag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  const handleAddition = (tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setTags([]);
  };

  useEffect(() => {
    if (values.documentNumber && values.documentTitle && values.content && tags.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [values, tags, file]);
  // ------------------------------------------------------//---------------------------------------------------

  return (
    <>
      {/* {console.log(file)} */}
      <Modal onShow={onShow} show={show} size="l" onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit Regulation</h5>
        </ModalHeader>
        <ModalBody>
          {loadingPage ? (
            <p>Loading.....</p>
          ) : (
            <>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="documentNumber">
                  Document Number
                </label>
                <input type="text" onChange={(e) => dispatch({ type: "documentNumber", value: e.target.value })} className="form-control" defaultValue={values.documentNumber} placeholder="POPD 15 002 SOP R0" id="documentNumber" />
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="documentTitle">
                  Document Title
                </label>
                <input type="text" onChange={(e) => dispatch({ type: "documentTitle", value: e.target.value })} className="form-control" defaultValue={values.documentTitle} placeholder="Super Planned Maintenance" id="documentTitle" />
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="content">
                  Content
                </label>
                <p>Berisi rangkuman dokumen yang anda upload</p>
                <textarea
                  placeholder="isi rangkuman dari prosedur yang akan di upload"
                  className="form-control"
                  defaultValue={values.content}
                  onChange={(e) => dispatch({ type: "content", value: e.target.value })}
                  rows={3}
                  id="content"
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="file">
                  File
                </label>
                <p>Jika ingin mengganti dokumen silahkan upload, maka dokumen lama akan otomatis diganti dengan dokumen baru</p>
                <p>Lebih dianjurkan menggunakan format PDF</p>
                <input type="file" onChange={(e) => setFile(e.target.files[0])} className="form-control" accept=".pdf,.jpg,.jpeg,.png,.ppt,.pptx" id="file" />
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="tag">
                  Tags
                </label>
                <p>Tags berisi kata kunci dari isi dokumen untuk memudahkan kita pada saat mencari document</p>
                <ReactTags
                  classNames={{ tags: "form-control", tag: "mx-1", clearAll: "btn text-theme" }}
                  tags={tags}
                  suggestions={suggest}
                  separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  onTagUpdate={onTagUpdate}
                  inputFieldPosition="bottom"
                  editable
                  clearAll
                  onClearAll={onClearAll}
                  // maxTags={10}
                />
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button onClick={() => submitClick()} type="button" className="btn btn-outline-theme" disabled={submitDisabled}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalRegulationEdit;
